import React, { useEffect, useState } from 'react';
import { getCompaniesAPI } from 'services/companiesApi';
import { Company } from 'models/Company';
import { ColumnFiltersState, createColumnHelper } from '@tanstack/react-table';
import { GlobalSearch } from 'components/GlobalSearch';
import { FilterContainer } from 'components/Filter/FilterContainer';
import { Filter } from 'components/Filter/Filter';
import { DashboardSkeleton } from 'components/Skeleton/DashboardSkeleton';
import CustomTable from 'components/Table/CustomTable';
import PageLayout from 'components/Layout/PageLayout';
import PageLayoutSkeleton from 'components/Layout/PageLayout/skeleton';

type FilterOptions = {
  categoryName: string[];
  status: string[];
  stripeAccountStatus: string[];
  accountCreatedNotificationStatus: string[];
};

export default function CompaniesTable() {
  const [companies, setCompanies] = useState<Company[]>();
  const [globalFilter, setGlobalFilter] = useState('');
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [filterOptions, setFilterOptions] = useState<FilterOptions>({
    categoryName: [],
    status: [],
    stripeAccountStatus: [],
    accountCreatedNotificationStatus: [],
  });

  useEffect(() => {
    getCompaniesAPI().then((companies: Company[]) => {
      setCompanies(companies);
      const temp: FilterOptions = {
        categoryName: [],
        status: [],
        stripeAccountStatus: [],
        accountCreatedNotificationStatus: [],
      };
      companies.forEach((company) => {
        if (!temp.categoryName.includes(company.categoryName)) temp.categoryName.push(company.categoryName);
        if (!temp.status.includes(company.status)) temp.status.push(company.status);
        if (!temp.stripeAccountStatus.includes(company.stripeAccountStatus))
          temp.stripeAccountStatus.push(company.stripeAccountStatus);
        if (!temp.accountCreatedNotificationStatus.includes(company.accountCreatedNotificationStatus))
          temp.accountCreatedNotificationStatus.push(company.accountCreatedNotificationStatus);
      });
      setFilterOptions(temp);
    });
  }, []);

  const setFilter = (columnId: string, filterValue: string) => {
    setColumnFilters((oldFilters) => {
      const updatedFilters = oldFilters.filter((filter) => filter.id !== columnId);
      if (filterValue) updatedFilters.push({ id: columnId, value: filterValue });
      return updatedFilters;
    });
  };

  const columnHelper = createColumnHelper<Company>();
  const columns = [
    columnHelper.accessor('name', {
      header: () => "NOME ATTIVITA'",
      cell: (name) => name.getValue(),
      enableColumnFilter: false,
      enableSorting: false,
    }),
    columnHelper.accessor('ownerEmail', {
      header: () => 'OWNER EMAIL',
      cell: (name) => name.getValue(),
      enableColumnFilter: false,
      enableSorting: false,
    }),
    columnHelper.accessor('categoryName', {
      header: () => 'CATEGORIA',
      cell: (categoryName) => categoryName.getValue(),
      enableColumnFilter: false,
      enableSorting: false,
    }),
    columnHelper.accessor('vatNumber', {
      header: () => 'P. IVA',
      cell: (vatNumber) => vatNumber.getValue(),
      enableColumnFilter: false,
      enableSorting: false,
    }),
    columnHelper.accessor('status', {
      header: () => 'STATO',
      cell: (status) => status.getValue(),
      enableColumnFilter: false,
      enableSorting: false,
    }),
    columnHelper.accessor('stripeAccountStatus', {
      header: () => 'STRIPE',
      cell: (status) => status.getValue(),
      enableColumnFilter: false,
      enableSorting: false,
    }),
    columnHelper.accessor('accountCreatedNotificationStatus', {
      header: () => 'STATO EMAIL',
      cell: (status) => status.getValue(),
      enableColumnFilter: false,
      enableSorting: false,
    }),
  ];

  return (
    <>
      {companies ? (
        <PageLayout titlePage="Lista attività">
          {companies.length === 0 ? (
            <h4>Non sono presenti attività registrate</h4>
          ) : (
            <>
              <GlobalSearch filterValue={globalFilter} onChange={(value) => setGlobalFilter(value)} />
              <FilterContainer>
                <Filter
                  columnId="categoryName"
                  options={filterOptions.categoryName}
                  placeholder="Categoria"
                  setFilter={setFilter}
                />
                <Filter columnId="status" options={filterOptions.status} placeholder="Stato" setFilter={setFilter} />
                <Filter
                  columnId="stripeAccountStatus"
                  options={filterOptions.stripeAccountStatus}
                  placeholder="Stripe"
                  setFilter={setFilter}
                />
                <Filter
                  columnId="accountCreatedNotificationStatus"
                  options={filterOptions.accountCreatedNotificationStatus}
                  placeholder="Stato Email"
                  setFilter={setFilter}
                />
              </FilterContainer>
              <CustomTable
                data={companies}
                columns={columns}
                pageStartIndex={0}
                pageMaxSize={10}
                columnsVisibility={{ vatNumber: false }}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
              />
            </>
          )}
        </PageLayout>
      ) : (
        <PageLayoutSkeleton>
          <DashboardSkeleton />
        </PageLayoutSkeleton>
      )}
    </>
  );
}
