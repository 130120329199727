import React, { ReactNode } from 'react';
import './style.css';
import Skeleton from 'react-loading-skeleton';

interface PageLayoutSkeletonProps {
  children: ReactNode;
}

export default function PageLayoutSkeleton({ children }: PageLayoutSkeletonProps) {
  return (
    <div className="container">
      <div className="title">
        <Skeleton />
      </div>
      <div className="sub-title">
        <Skeleton />
      </div>
      {children}
    </div>
  );
}
