import React, { ChangeEvent } from 'react';
import './style.css';

interface InputProps {
  title?: string;
  text: string;
  textError?: string;
  placeholder: string;
  isReadOnly?: boolean;
  handleChange?: (event: ChangeEvent<HTMLInputElement>) => void;
}
export default function Input({ title, text, textError, placeholder, isReadOnly = false, handleChange }: InputProps) {
  return (
    <div className="input-container">
      {title ? <label className="input-label">{title}</label> : null}
      <input
        className="input-box"
        value={text === null ? undefined : text}
        onChange={handleChange}
        placeholder={placeholder}
        readOnly={isReadOnly}
      />
      {textError !== null ? <div style={{ color: '#ff0000', fontSize: '10px' }}>{textError}</div> : null}
    </div>
  );
}
