import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './store';

export interface AuthState {
  accessToken: string | undefined;
  displayName: string | undefined;
  email: string | undefined;
}

const initialState: AuthState = {
  accessToken: undefined,
  displayName: undefined,
  email: undefined,
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    login: (_, action: PayloadAction<AuthState & { accessToken: string }>) => {
      return action.payload;
    },
    logout: () => {
      return {
        accessToken: undefined,
        displayName: undefined,
        email: undefined,
      };
    },
  },
});

export const selectAuth = (state: RootState) => state.auth;
export const { login, logout } = authSlice.actions;
export default authSlice.reducer;
