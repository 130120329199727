import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface NavigationState {
  path: string | null;
}

const initialState: NavigationState = {
  path: null,
};

const navigationSlice = createSlice({
  name: 'navigation',
  initialState,
  reducers: {
    navigate: (state, action: PayloadAction<string>) => {
      state.path = action.payload;
    },
  },
});

export const { navigate } = navigationSlice.actions;
export default navigationSlice.reducer;
