import React from 'react';
import { useAuth } from 'oidc-react';
import { ReactNode, useEffect } from 'react';
import { Provider, useDispatch } from 'react-redux';
import { login, logout } from '../store/authSlice';
import { RootState, store } from '../store/store';
import { useNavigate } from 'react-router-dom';
import { useAppSelector } from 'hook/useReduxHooks';

const StoreHandler = ({ children }: Readonly<{ children: ReactNode }>) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const path = useAppSelector((state: RootState) => state.navigation.path);
  const { userData } = useAuth();

  useEffect(() => {
    if (path) {
      navigate(path);
    }
  }, [path, navigate]);

  useEffect(() => {
    if (userData) {
      dispatch(
        login({
          accessToken: userData.access_token,
          displayName: userData.profile.name,
          email: userData.profile.email,
        })
      );
    } else {
      dispatch(logout());
    }
  }, [userData, dispatch]);

  return <>{children}</>;
};

const StoreProvider = ({ children }: Readonly<{ children: ReactNode }>) => {
  return (
    <Provider store={store}>
      <StoreHandler>{children}</StoreHandler>
    </Provider>
  );
};

export default StoreProvider;
