import { RegistrationRequestApprove, RegistrationRequestApproveRestoreResponse } from 'models/RegistrationRequest';
import api from './api';
import { toastPromise } from 'utils/toastMethod';

export async function getRegistrationRequestsAPI() {
  return api.get(`/api/backoffice/RegistrationRequests`).then((response) => response.data);
}

export async function approveRegistrationRequestAPI(
  registrationRequestId: string | undefined,
  payload: RegistrationRequestApprove
) {
  return new Promise<RegistrationRequestApproveRestoreResponse>((resolve) => {
    toastPromise(
      api
        .put(`/api/backoffice/RegistrationRequests/${registrationRequestId}/approve`, payload)
        .then((response) => resolve(response.data)),
      'Operazione in corso...',
      'Operazione completata!',
      "Qualcosa è andato storto. Verifica se che l'attività non sia già presente e riprova."
    );
  });
}

export async function restoreRegistrationRequestAPI(registrationRequestId: string | undefined) {
  return new Promise<RegistrationRequestApproveRestoreResponse>((resolve, reject) => {
    api
      .put(`/api/backoffice/RegistrationRequests/${registrationRequestId}/restore`)
      .then((response) => resolve(response.data))
      .catch((err) => reject(err));
  });
}
