import React, { useState } from 'react';
import { useAuth } from 'oidc-react';
import { CustomCard } from '../CustomCard';
import logoContract from '../../assets/images/bss-logo-contract.svg';

export default function AuthRequired() {
  const auth = useAuth();
  const [loading, setLoading] = useState(false);

  return (
    <CustomCard padding="50px">
      <img style={{ marginBottom: '30px', width: '250px' }} alt="logoContract" src={logoContract} />
      <div className="text-muted" style={{ marginBottom: '30px' }}>
        Premi Log In per accedere al backoffice
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <button
          type="button"
          className="btn btn-primary"
          disabled={auth.isLoading || loading}
          style={{ width: '100px' }}
          onClick={() => {
            setLoading(true);
            auth.userManager
              .signinRedirect({
                state: { redirect: window.location.href.substring(window.location.origin.length) },
              })
              .finally(() => setLoading(false));
          }}
        >
          Log In
        </button>
      </div>
    </CustomCard>
  );
}
