import React, { useEffect, useState } from 'react';
import { ColumnFiltersState, createColumnHelper } from '@tanstack/react-table';
import { RegistrationRequest } from 'models/RegistrationRequest';
import { GlobalSearch } from 'components/GlobalSearch';
import { Filter } from 'components/Filter/Filter';
import { FilterContainer } from 'components/Filter/FilterContainer';
import { DashboardSkeleton } from 'components/Skeleton/DashboardSkeleton';
import RowActions from 'components/Table/RowActions';
import CustomTable from 'components/Table/CustomTable';
import PageLayout from 'components/Layout/PageLayout';
import PageLayoutSkeleton from 'components/Layout/PageLayout/skeleton';
import { getRegistrationRequestsAPI } from 'services/registrationRequestsApi';

type FilterOptions = {
  categoryName: string[];
  shopTown: string[];
  status: string[];
};

export default function RegistrationRequestsTable() {
  const [registrationRequests, setRegistrationRequests] = useState<RegistrationRequest[]>();
  const [globalFilter, setGlobalFilter] = useState('');
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>([]);
  const [filterOptions, setFilterOptions] = useState<FilterOptions>({ categoryName: [], shopTown: [], status: [] });

  useEffect(() => {
    getRegistrationRequestsAPI().then((registrationRequests: RegistrationRequest[]) => {
      setRegistrationRequests(registrationRequests);
      const temp: FilterOptions = { categoryName: [], shopTown: [], status: [] };
      registrationRequests.forEach((request) => {
        if (!temp.categoryName.includes(request.category.name)) temp.categoryName.push(request.category.name);
        if (!temp.shopTown.includes(request.shopTown.name)) temp.shopTown.push(request.shopTown.name);
        if (!temp.status.includes(request.status)) temp.status.push(request.status);
      });
      setFilterOptions(temp);
    });
  }, []);

  const setFilter = (columnId: string, filterValue: string) => {
    setColumnFilters((oldFilters) => {
      const updatedFilters = oldFilters.filter((filter) => filter.id !== columnId);
      if (filterValue) updatedFilters.push({ id: columnId, value: filterValue });
      return updatedFilters;
    });
  };

  const columnHelper = createColumnHelper<RegistrationRequest>();
  const columns = [
    columnHelper.accessor('companyName', {
      header: () => "NOME ATTIVITA'",
      cell: (companyName) => companyName.getValue(),
      enableSorting: false,
    }),
    columnHelper.accessor('ownerName', {
      header: () => 'OWNER',
      cell: (companyName) => companyName.getValue(),
      enableSorting: false,
    }),
    columnHelper.accessor('category.name', {
      id: 'categoryName',
      header: () => 'CATEGORIA',
      cell: (category) => category.getValue(),
      enableSorting: false,
    }),
    columnHelper.accessor('shopAddress', {
      header: () => 'INDIRIZZO',
      cell: (shopAddress) => shopAddress.getValue(),
      enableSorting: false,
    }),
    columnHelper.accessor('shopTown.name', {
      id: 'shopTown',
      header: () => "CITTA'",
      cell: (shopTown) => shopTown.getValue(),
      enableSorting: false,
    }),
    columnHelper.accessor('ownerPhoneNumber', {
      header: () => 'TELEFONO',
      cell: (ownerPhoneNumber) => ownerPhoneNumber.getValue(),
      enableSorting: false,
    }),
    columnHelper.accessor('ownerEmail', {
      header: () => 'EMAIL',
      cell: (ownerEmail) => ownerEmail.getValue(),
      enableSorting: false,
    }),
    columnHelper.accessor('created', {
      header: () => 'DATA RICHIESTA',
      cell: (ownerEmail) => {
        const date = new Date(ownerEmail.getValue() as string);
        return date.toLocaleDateString('it-IT', {
          year: 'numeric',
          month: '2-digit',
          day: '2-digit',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
        });
      },
      enableSorting: true,
      sortingFn: (rowA, rowB, columnId) => {
        const dateA = new Date(rowA.getValue(columnId) as string);
        const dateB = new Date(rowB.getValue(columnId) as string);
        return dateA.getTime() - dateB.getTime();
      },
    }),
    columnHelper.accessor('status', {
      header: () => 'STATO',
      cell: (status) => status.getValue(),
      enableSorting: false,
    }),
    columnHelper.display({
      header: 'AZIONI',
      cell: ({ row }) => (
        <RowActions
          registrationRequest={row.original}
          registrationRequests={registrationRequests}
          setRegistrationRequests={setRegistrationRequests}
        />
      ),
    }),
  ];

  return (
    <>
      {registrationRequests ? (
        <PageLayout titlePage="Richieste di registrazione">
          {registrationRequests.length === 0 ? (
            <h4>Non è stata fatta nessuna richiesta di registrazione</h4>
          ) : (
            <>
              <GlobalSearch filterValue={globalFilter} onChange={(value) => setGlobalFilter(value)} />
              <FilterContainer>
                <Filter
                  columnId="categoryName"
                  options={filterOptions.categoryName}
                  placeholder="Categoria"
                  setFilter={setFilter}
                />
                <Filter
                  columnId="shopTown"
                  options={filterOptions.shopTown}
                  placeholder="Città"
                  setFilter={setFilter}
                />
                <Filter columnId="status" options={filterOptions.status} placeholder="Stato" setFilter={setFilter} />
              </FilterContainer>
              <CustomTable
                data={registrationRequests}
                columns={columns}
                pageStartIndex={0}
                pageMaxSize={10}
                columnsVisibility={{}}
                globalFilter={globalFilter}
                setGlobalFilter={setGlobalFilter}
                columnFilters={columnFilters}
                setColumnFilters={setColumnFilters}
              />
            </>
          )}
        </PageLayout>
      ) : (
        <PageLayoutSkeleton>
          <DashboardSkeleton />
        </PageLayoutSkeleton>
      )}
    </>
  );
}
