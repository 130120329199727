import React, { useState, useEffect } from 'react';
import Input from './Input';

const DEFAULT_DEBOUNCE = 200;

interface GlobalSearchProps {
  filterValue: string;
  onChange: (value: string) => void;
  debounce?: number;
  placeholder?: string;
}

export const GlobalSearch = ({
  filterValue,
  onChange,
  debounce = DEFAULT_DEBOUNCE,
  placeholder = 'Cerca in tutta la tabella...',
}: GlobalSearchProps) => {
  const [value, setValue] = useState(filterValue);

  useEffect(() => {
    setValue(filterValue);
  }, [filterValue]);

  useEffect(() => {
    const timeout = setTimeout(() => {
      onChange(value);
    }, debounce);
    return () => clearTimeout(timeout);
  }, [debounce, onChange, value]);

  return (
    <Input
      text={value}
      placeholder={placeholder}
      handleChange={(e) => {
        setValue(e.target.value);
      }}
    />
  );
};
