import React from 'react';
import InputSkeleton from '../Input/skeleton';

export const DashboardSkeleton = () => {
  return (
    <>
      <InputSkeleton />
      <InputSkeleton />
      <InputSkeleton />
    </>
  );
};
