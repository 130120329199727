import React, { useEffect, useRef, useState } from 'react';
import './style.css';

interface FilterProps {
  columnId: string;
  options: string[];
  placeholder: string;
  setFilter: (columnId: string, filterValue: string) => void;
}

export const Filter: React.FC<FilterProps> = ({ columnId, options, placeholder, setFilter }) => {
  const [showSelector, setShowSelector] = useState(false);
  const [showClose, setShowClose] = useState(false);
  const [selectedOption, showSelectedOption] = useState<string | undefined>('');
  const selectorRef = useRef<HTMLUListElement | null>(null);

  const handleSelectorClick = () => {
    setShowSelector(!showSelector);
  };

  const handleOptionClick = (columnId: string, option: string) => {
    setShowSelector(false);
    setFilter(columnId, option);
    setShowClose(true);
    showSelectedOption(option);
  };

  const handleSelectorCloseClick = (columnId: string) => {
    setShowSelector(false);
    setFilter(columnId, '');
    setShowClose(false);
    showSelectedOption(undefined);
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (selectorRef.current && !selectorRef.current.contains(event.target as Node)) {
      setShowSelector(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <div className="filter">
      <div className="filter-selector">
        {showClose ? (
          <span className="filter-selector-close" onClick={() => handleSelectorCloseClick(columnId)}>
            &#x2715;
          </span>
        ) : null}
        <div onClick={handleSelectorClick}>
          {placeholder}
          {selectedOption ? `: ${selectedOption}` : null}
        </div>
      </div>

      {showSelector && (
        <ul ref={selectorRef}>
          {options.map((option) => (
            <li
              key={option}
              value={option}
              style={{ cursor: 'pointer' }}
              onClick={() => handleOptionClick(columnId, option)}
            >
              {option}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
