import React from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { Slide, ToastContainer } from 'react-toastify';
import HomePage from 'pages/HomePage';
import RegistrationRequestsTable from 'pages/RegistrationRequestsTable';
import CompaniesTable from 'pages/CompaniesTable';
import AuthProvider from 'context/AuthProvider';
import StoreProvider from 'context/StoreProvider';
import Layout from 'components/Layout/Layout';
import AuthRequiredPage from 'components/Auth/AuthRequiredPage';
import ErrorAuthorizationPage from 'pages/ErrorAuthorizationPage';
import '@fontsource/poppins';
import '@fontsource/poppins/400.css';
import '@fontsource/poppins/500.css';
import '@fontsource/poppins/600.css';
import '@fontsource/poppins/700.css';
import './App.css';
import 'react-toastify/dist/ReactToastify.css';
import 'react-loading-skeleton/dist/skeleton.css';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <AuthProvider>
          <StoreProvider>
            <Routes>
              <Route
                path="/"
                element={
                  <AuthRequiredPage>
                    <Layout />
                  </AuthRequiredPage>
                }
              >
                <Route path="/" element={<HomePage />} />
                <Route path="/registrationRequests" element={<RegistrationRequestsTable />} />
                <Route path="/companies" element={<CompaniesTable />} />
                <Route path="/error-auth" element={<ErrorAuthorizationPage />} />
                <Route path="*" element={<Navigate to="/registrationRequests" />} />
              </Route>
            </Routes>
          </StoreProvider>
        </AuthProvider>
      </BrowserRouter>
      <ToastContainer theme="colored" position="bottom-right" autoClose={4000} transition={Slide} />
    </div>
  );
}

export default App;
