import React from 'react';
import './style.css';

interface FilterContainerProps {
  children?: React.ReactNode;
}

export const FilterContainer: React.FC<FilterContainerProps> = ({ children }) => {
  return <div className="filter-container">{children}</div>;
};
