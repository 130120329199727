import React from 'react';
import Skeleton from 'react-loading-skeleton';
import './style.css';

export default function InputSkeleton() {
  return (
    <div className="input-container">
      <div className="input-label">
        <Skeleton />
      </div>
      <Skeleton height={40} />
    </div>
  );
}
