import React from 'react';
import { WebStorageStateStore } from 'oidc-client-ts';
import { AuthProvider as BaseAuthProvider, UserManager } from 'oidc-react';
import { ReactNode } from 'react';
import { useNavigate } from 'react-router';

const oidcConfig = {
  authority: process.env.REACT_APP_OIDC_AUTHORITY !== undefined ? process.env.REACT_APP_OIDC_AUTHORITY : '',
  client_id: process.env.REACT_APP_OIDC_CLIENT_ID !== undefined ? process.env.REACT_APP_OIDC_CLIENT_ID : '',
  automaticSilentRenew: true,
  redirect_uri: window.location.origin,
  post_logout_redirect_uri: window.location.origin,
  stateStore: new WebStorageStateStore({ store: window.sessionStorage }),
  userStore: new WebStorageStateStore({ store: window.localStorage }),
};
const userManager = new UserManager(oidcConfig);

export const userSigninRedirect = async () => {
  await userManager.signinRedirect();
};

export const userLogout = async () => {
  await userManager.signoutSilent();
};

export default function AuthProvider({ children }: Readonly<{ children: ReactNode }>) {
  const navigate = useNavigate();

  return (
    <BaseAuthProvider
      autoSignIn={false}
      userManager={userManager}
      onSignIn={(user) => {
        if (
          user?.state &&
          typeof user.state === 'object' &&
          'redirect' in user.state &&
          typeof user.state.redirect === 'string'
        ) {
          navigate(user.state.redirect, { replace: true });
        } else {
          navigate('/', { replace: true });
        }
      }}
    >
      {children}
    </BaseAuthProvider>
  );
}
