import React, { ReactNode } from 'react';
import './style.css';

interface PageLayoutProps {
  titlePage: string;
  subtitlePage?: string;
  children: ReactNode;
}

export default function PageLayout({ titlePage, subtitlePage, children }: PageLayoutProps) {
  return (
    <div className="container" style={{ fontSize: '14px' }}>
      <div className="title">{titlePage}</div>
      {subtitlePage ? <div className="sub-title">{subtitlePage}</div> : null}
      {children}
    </div>
  );
}
