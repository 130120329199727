import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import './style.css';

export default function SideBar() {
  const location = useLocation();

  return (
    <>
      <nav className={'sidebar open'}>
        <ul>
          <li className={location.pathname === `/registrationRequests` ? 'selected' : ''}>
            <Link to={`/registrationRequests`}>Richieste di registrazione</Link>
          </li>
          <li className={location.pathname === `/companies` ? 'selected' : ''}>
            <Link to={`/companies`}>Lista attività registrate</Link>
          </li>
        </ul>
      </nav>
    </>
  );
}
