import React, { ReactNode } from 'react';

interface CustomCardProps {
  title?: ReactNode;
  children: ReactNode;
  footer?: ReactNode;
  padding?: string;
  width?: string;
  height?: string;
}

export const CustomCard = ({ title, children, footer, padding = '20px', width, height }: CustomCardProps) => {
  return (
    <div
      className="card"
      style={{
        backgroundColor: '#ffffff',
        borderRadius: '16px',
        padding,
        minHeight: '50px',
        width,
        height,
      }}
    >
      {title ? <div>{title}</div> : null}
      {children ? <div>{children}</div> : null}
      {footer ? <div>{footer}</div> : null}
    </div>
  );
};
