import React from 'react';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';

interface CustomTooltipProps {
  children: JSX.Element;
  message: string;
  id: string;
}
export const CustomTooltip = ({ id, message, children }: CustomTooltipProps) => {
  return (
    <OverlayTrigger placement="top" overlay={<Tooltip id={id}>{message}</Tooltip>}>
      {({ ref, ...triggerHandler }) => (
        <span ref={ref as React.LegacyRef<HTMLSpanElement>} {...triggerHandler}>
          {children}
        </span>
      )}
    </OverlayTrigger>
  );
};
